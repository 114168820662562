import React, { FC } from 'react'
import dynamic from 'next/dynamic'

const PressList = dynamic(() => import('../components/Press'))

interface Props {
  title: string,
  data: Array<any>
}

const PressContainer: FC<Props> = ({ title, data }) => <PressList title={title} articles={data} />

export default PressContainer
